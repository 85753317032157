.heading{
    font-size: 1.8rem;
    font-family: "AvenirNext Bold",sans-serif;
    letter-spacing: 0.03px;
    color: #d63827;
    font-weight: bold;
}
.user-form {
  background-color: #f0f1f4;
  border-radius: 5px;
  padding: 30px 38px;
}
.user-form label {
    display: flex;
    margin-bottom: 5px;
    font-size: 16px;
      color: #6d6d6d;
      font-family: arial, helvetica, sans-serif;
      font-weight: normal;
  }
 
  .user-form input[type="text"],
  .user-form input[type="email"],
  .user-form input[type="number"],
  .user-form input[type="date"],
  .user-form select,
  .user-form textarea {
    display: block;
    width: 400px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .error {
    color: red;
    font-size: 12px;
    text-align: left;
    margin-top: 2px;
  }
  .formControl {
    margin-bottom: 20px;
  }
  .submit-btn{
      font-size: 16px;
      padding: 10px 30px;
      font-weight: normal;
      background: #d63827;
      border-color: #d63827;
      color: #ffffff;
      line-height: 12px;
      border-radius: 3px;
      border-style: solid;
      border-width: 1px;
      line-height: 25px;
  }
  .submit-btn:disabled {
    opacity: 0.6; /* Reduce opacity to visually indicate disabled state */
    cursor: not-allowed; /* Change cursor to indicate not clickable */
}
  .back-btn{
    background: #D63827;
    padding: 6px 8px;
    color: #fff;
    border: 0;
    font-size: 1rem;
    font-family: "AvenirNext Medium",sans-serif;
    letter-spacing: 0.03px;
}
.success-text{
  font-size: 18px;
  color: #508D4E;
}
.error-text{
  font-size: 18px;
  color: #C80036;
}

.suggestions-list {
  z-index: 1;
  width:100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 0;
  padding: 0;
  list-style-type: none;
}

.suggestion-item {
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}